import Alpine from "alpinejs";

declare const turnstile: any;

Alpine.directive("turnstile", (el: HTMLElement, { expression }: { expression: string }, { effect, cleanup }) => {
  let siteKey: string = expression;
  let widgetId: string | null = null;
  let form: HTMLFormElement | null = el.closest("form");
  let warningMessage: Element | null = el.querySelector("[x-ref='warningMessage']");

  form?.querySelector('button[type="submit"]')?.setAttribute("disabled", "disabled");
  
  if (typeof turnstile === "undefined") {
    warningMessage?.classList?.remove("hidden");
    throw new Error("Cloudflare Turnstile script is not loaded.");
  }

  let hiddenInput = document.createElement("input");
  hiddenInput.type = "hidden";
  hiddenInput.name = "cf_turnstile_response";
  form?.appendChild(hiddenInput);
  
  function renderTurnstile() {
    widgetId = turnstile.render(el, {
      sitekey: siteKey,
      size: "flexible",
      callback: (token: string) => {
        hiddenInput.value = token;
        form?.querySelector("button[type='submit']")?.removeAttribute("disabled");
      }
    });
  }
  
  effect(() => {
    renderTurnstile();
  });
  
  cleanup(() => {
    if (widgetId !== null) {
      turnstile.remove(widgetId);
    }
  });
});
