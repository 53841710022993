import Rails from "@rails/ujs";
import Alpine from "alpinejs";
import "@/alpine/directives/generate-certificate-fingerprint-from";
import "@/alpine/directives/read-file-contents-to";
import "@/alpine/directives/turnstile";

Rails.start();
Alpine.start();

setTimeout(() => {
  const flashes = document.querySelectorAll(".flash");
  flashes.forEach(function (flash) {
    flash.parentNode?.removeChild(flash);
  });
}, 5000);
